import { AxiosError } from 'axios';
import { GetServerSideProps, PreviewData } from 'next';
import { ParsedUrlQuery } from 'querystring';

import {
  LESSEE_TOKEN,
  REALTOR_DETAIL_DATA,
  REALTOR_TOKEN,
  ZARITALK_ADMIN_TOKEN,
  ZARITALK_TOKEN,
} from '@zaritalk/constants';
import { MEMBER_TYPE } from '@zaritalk/types';

import { serverSideErrorHandler } from './errorHandler';
import { getExpiresDate, isClientSide } from '../index';

type TokenInfo = {
  PK: number;
  role: MEMBER_TYPE | 'SUB_ACCOUNT_LESSOR' | '';
  admin: boolean;
  exp: number;
};

export function parseJWT(token: string): TokenInfo {
  try {
    if (token !== '' && token.split('.').length > 1) {
      const base64Payload = token.split('.')[1];

      if (isClientSide()) {
        const parseToken = JSON.parse(atob(base64Payload));
        const isNewVersion = parseToken?.version;

        return {
          PK: isNewVersion ? parseToken.profilePk : parseToken.PK,
          role: isNewVersion ? parseToken.profileType ?? 'NONE' : parseToken.role ?? 'NONE',
          admin: parseToken?.admin ?? false,
          exp: parseToken.exp,
        };
      } else {
        const parseToken = JSON.parse(Buffer.from(base64Payload, 'base64').toString());
        const isNewVersion = parseToken?.version;

        return {
          PK: isNewVersion ? parseToken.profilePk : parseToken.PK,
          role: isNewVersion ? parseToken.profileType ?? 'NONE' : parseToken.role ?? 'NONE',
          admin: parseToken?.admin ?? false,
          exp: parseToken.exp,
        };
      }
    }

    return { PK: -1, role: '', exp: -1, admin: false };
  } catch (e) {
    return { PK: -1, role: '', exp: -1, admin: false };
  }
}

export function withLessorSession<
  P extends { [key: string]: any } = { [key: string]: any },
  Q extends ParsedUrlQuery = ParsedUrlQuery,
  D extends PreviewData = PreviewData,
>(handler: GetServerSideProps<P, Q, D>, isMember = true): GetServerSideProps<P, Q, D> {
  return async (ctx) => {
    try {
      if (isMember) {
        const { req } = ctx;
        const authToken = req.cookies[ZARITALK_TOKEN];

        if (!authToken) {
          const newError = new AxiosError('not found user');
          newError.status = 401;
          throw newError;
        }

        const { role } = parseJWT(authToken);
        if (role !== 'LESSOR' && role !== 'SUB_ACCOUNT_LESSOR') {
          const newError = new AxiosError('not found user');
          newError.status = 401;
          throw newError;
        }
      }

      return handler(ctx);
    } catch (err) {
      return serverSideErrorHandler(err, ctx);
    }
  };
}

export function withRealtorSession<
  P extends { [key: string]: any } = { [key: string]: any },
  Q extends ParsedUrlQuery = ParsedUrlQuery,
  D extends PreviewData = PreviewData,
>(handler: GetServerSideProps<P, Q, D>, isMember = true): GetServerSideProps<P, Q, D> {
  return async (ctx) => {
    try {
      if (isMember) {
        const { req, query } = ctx;
        const { vacancyUuid, historyKey } = query;
        const authToken = req.cookies[REALTOR_TOKEN];

        if (!authToken) {
          const { role } = parseJWT(authToken);
          if (role !== 'REALTOR') {
            const newError = new AxiosError('not found user');
            newError.status = 401;
            throw newError;
          }

          const expireDate = getExpiresDate(365);
          ctx.res.setHeader(
            'Set-Cookie',
            `${REALTOR_DETAIL_DATA}=${vacancyUuid}/${historyKey}; path=/; expires=${expireDate};`,
          );

          return {
            redirect: {
              destination: '/',
              permanent: false,
            },
          };
        }
      }

      return handler({ ...ctx });
    } catch (err) {
      return serverSideErrorHandler(err, ctx);
    }
  };
}

export function withLesseeSession<
  P extends { [key: string]: any } = { [key: string]: any },
  Q extends ParsedUrlQuery = ParsedUrlQuery,
  D extends PreviewData = PreviewData,
>(handler: GetServerSideProps<P, Q, D>, isMember = true): GetServerSideProps<P, Q, D> {
  return async (ctx) => {
    try {
      if (isMember) {
        const { req } = ctx;
        const authToken = req.cookies[LESSEE_TOKEN];

        if (!authToken) {
          const newError = new AxiosError('not found user');
          newError.status = 401;
          throw newError;
        }

        const { role } = parseJWT(authToken);
        if (role !== 'LESSEE') {
          const newError = new AxiosError('not found user');
          newError.status = 401;
          throw newError;
        }
      }
      return handler(ctx);
    } catch (err) {
      return serverSideErrorHandler(err, ctx);
    }
  };
}

export function withAdminSession<
  P extends { [key: string]: any } = { [key: string]: any },
  Q extends ParsedUrlQuery = ParsedUrlQuery,
  D extends PreviewData = PreviewData,
>(handler: GetServerSideProps<P, Q, D>, isMember = true): GetServerSideProps<P, Q, D> {
  return async (ctx) => {
    try {
      if (isMember) {
        const { req } = ctx;
        const authToken = req.cookies[ZARITALK_ADMIN_TOKEN];

        if (!authToken) {
          const newError = new AxiosError('not found user');
          newError.status = 401;
          throw newError;
        }
      }

      return handler(ctx);
    } catch (err) {
      return serverSideErrorHandler(err, ctx);
    }
  };
}

export function withAdminToken<
  P extends { [key: string]: any } = { [key: string]: any },
  Q extends ParsedUrlQuery = ParsedUrlQuery,
  D extends PreviewData = PreviewData,
>(handler: GetServerSideProps<P, Q, D>): GetServerSideProps<P, Q, D> {
  return async (ctx) => {
    try {
      const authToken = ctx.req.cookies[process.env.LOGIN_TOKEN_NAME as string];
      const { admin } = parseJWT(authToken);

      if (!admin) {
        return {
          redirect: {
            destination: '/',
            permanent: false,
          },
        };
      }

      return handler(ctx);
    } catch (err) {
      return serverSideErrorHandler(err, ctx);
    }
  };
}
