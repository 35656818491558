import { memo, ReactElement } from 'react';

import { SVGIconProps } from '../';

const NavRealEstateIcon = memo(
  ({ fill = '#222222', height = '24px', className, onClick }: SVGIconProps): ReactElement => {
    return (
      <svg className={className} height={height} fill={fill} onClick={onClick} viewBox={'0 0 24 24'}>
        <path
          d={
            'M3 12.4806C3 12.1768 3.13809 11.8895 3.3753 11.6998L5.5 10L7.9734 7.93884C8.29076 7.67436 8.73809 7.63227 9.09922 7.8329L12.3852 9.65843C12.754 9.86334 13.2118 9.81469 13.5293 9.53685L19.3415 4.45119C19.9881 3.88543 21 4.34461 21 5.20377V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V12.4806Z'
          }
          fill={fill}
        />
      </svg>
    );
  },
);

export default NavRealEstateIcon;
