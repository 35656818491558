import { ReactNode } from 'react';

interface IfProps {
  children: ReactNode;
  condition: boolean;
}

export default function If({ children, condition }: IfProps) {
  return condition ? <>{children}</> : null;
}
