import { CSSProperties, MouseEventHandler } from 'react';

import { COLOR, Z_INDEX } from '@zaritalk/constants';
import { css } from '@zaritalk/panda-css/css';
import { styled } from '@zaritalk/panda-css/jsx';

import { Icon } from './';

type TooltipPosition = 'top' | 'bottom' | 'left' | 'right' | 'topRight';

interface ITooltipProps {
  text?: string;
  direction: TooltipPosition;
  className?: string;
  containerClassName?: string;
  tooltipStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  textStyle?: CSSProperties;
  useClose?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  arrowStyle?: CSSProperties;
  children?: string | JSX.Element | Array<string | JSX.Element>;
}

export default function Tooltip({
  text = '',
  direction,
  className,
  containerClassName,
  tooltipStyle = {},
  containerStyle = {},
  textStyle = {},
  useClose = true,
  onClick,
  arrowStyle = {},
  children,
}: ITooltipProps) {
  return (
    <TooltipC className={className} style={tooltipStyle}>
      <TooltipCC className={containerClassName} onClick={onClick} style={containerStyle}>
        {text !== '' && (
          <TooltipText
            dangerouslySetInnerHTML={{ __html: text }}
            css={{ marginRight: useClose ? '8px' : 0 }}
            style={{ color: COLOR.WHITE, ...textStyle }}
          />
        )}
        {children}
        {useClose && <Icon.CloseIcon className={closeCSS} height={'24px'} fill={COLOR.WHITE} />}
      </TooltipCC>
      <TooltipArrow direction={direction} style={arrowStyle} />
    </TooltipC>
  );
}

const closeCSS = css({
  cursor: 'pointer',
});

const TooltipC = styled('section', {
  base: {
    position: 'relative',
    zIndex: 'token(zIndex.$TOOLTIP)',

    textAlign: 'center',

    backgroundColor: '$murkyBlue',
    borderRadius: '5px',
  },
});

const TooltipCC = styled('section', {
  base: {
    flexRow: 'CENTER_START',
    width: '100%',
    height: '100%',

    backgroundColor: '$murkyBlue',
    borderRadius: '5px',

    padding: '12px 14px',
  },
});

const TooltipText = styled('span', {
  base: {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: 1.43,

    color: '$white',
    whiteSpace: 'pre-wrap',
  },
});

const TooltipArrow = styled('div', {
  base: {
    position: 'absolute',

    width: '10px',
    height: '10px',
    backgroundColor: '$murkyBlue',
  },

  variants: {
    direction: {
      left: {
        left: 'calc(-5px)',
        top: 'calc(20%)',
        transform: 'rotateZ(-45deg)',
      },
      right: {
        right: 'calc(-5px)',
        top: 'calc(20%)',
        transform: 'rotateZ(135deg)',
      },
      bottom: {
        right: '20%',
        top: 'calc(100% - 5px)',
        transform: 'rotateZ(-135deg)',
      },
      top: {
        left: '20%',
        top: 'calc(-5px)',
        transform: 'rotateZ(45deg)',
      },
      topRight: {
        right: '20%',
        top: 'calc(-5px)',
        transform: 'rotateZ(45deg)',
      },
    },
  },
});
