import { LESSEE_ROUTES } from './route';

export const REDIRECT_TARGET_PAGE_PATH = {
  KAKAO_INTRO: LESSEE_ROUTES.KAKAO_INTRO,
  PAY_FROM_KAKAO: LESSEE_ROUTES.KAKAO_PAY,
  PAY_FROM_KAKAO_SYNC: LESSEE_ROUTES.PAY_KAKAO_SYNC,
} as const;

export const APPLICATION_ID_OBJECT = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: '자리톡',
  url: 'https://zaritalk.com/',
  sameAs: [
    'https://apps.apple.com/kr/app/id1583506656',
    'https://play.google.com/store/apps/details?id=com.callbus.zaritalk&hl=ko',
    'https://twitter.com/zaritalk',
  ],
};
