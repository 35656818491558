export const COLOR = {
  BLACK: '#222222',
  BLACK02: '#121212',

  WHITE: '#ffffff',

  GRAY06: '#3d3d3d',
  GRAY05: '#7a7a7a',
  GRAY04: '#b4b4b4',
  GRAY03: '#c8c8c8',
  GRAY02: '#e8e8e8',
  GRAY01: '#f2f2f2',
  GRAY00: '#f8f8f8',

  PRIMARY03: '#eaf2ff',
  PRIMARY02: '#dbe9ff',
  PRIMARY01: '#2c7fff',

  RED: '#e74d5a',
  RED03: '#fef2f3',
  VIVID_RED: '#ff385c',

  YELLOW: '#ffbd35',
  YELLOW02: '#eca20c',
  KAKAO_YELLOW: '#ffd94a',

  GREEN: '#33b34d',
  GREEN02: '#e3f8e7',

  NAVER_GREEN: '#03c75a',

  LIGHT_GREEN01: '#caf9f0',

  SEJONG_GREEN01: '#34795c',
  SEJONG_GREEN02: '#b8ddcd',
  SEJONG_GREEN03: '#92c7b0',

  DARK_SKY_BLUE: '#4785e5',
  AZURE_BLUE: '#2496ff',
  DEEP_BLUE: '#1e45ac',
  MURKY_BLUE: '#76849c',

  // 수납 관련 색상
  PAID_ALL: '#33b34d', // 수납
  OVER_DEPOSIT: '#006214', // 초과
  PAID_PARTIAL: '#ff940e', // 일부
  SCHEDULED: '#ffbd35', // 예정
  UNPAID: '#e74d5a', // 미납
};

export const BOTTOM_NAV_HEIGHT = 58;
