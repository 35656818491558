import { GetServerSidePropsContext } from 'next';

import { getUserAgent } from '@zaritalk/utils/lib/userAgent';

export const isZaritalkApp = (ctx?: GetServerSidePropsContext): boolean => {
  const zaritalkAppUserAgent = getUserAgent(ctx).toLowerCase();
  return zaritalkAppUserAgent.match(/zaritalkapp\S([0-9.]*)/) !== null;
};

export const getZaritalkAppVersion = (ctx?: GetServerSidePropsContext): string => {
  if (!isZaritalkApp(ctx)) {
    console.warn('this device is not app');
    return '';
  }

  const zaritalkAppUserAgent = getUserAgent(ctx).toLowerCase();
  const zaritalkAppUserAgentMatch = zaritalkAppUserAgent.match(/zaritalkapp\S([0-9.]*)/);

  return zaritalkAppUserAgentMatch?.length === 2 ? zaritalkAppUserAgentMatch[1] : '';
};

export const checkTargetAppVersion = (targetVersion: string, ctx?: GetServerSidePropsContext): boolean => {
  if (getZaritalkAppVersion(ctx) === '') {
    return false;
  }
  const targetVersionSplit = targetVersion.split('.').map(Number);
  const currentVersionSplit = getZaritalkAppVersion(ctx).split('.').map(Number);

  return (
    currentVersionSplit[0] > targetVersionSplit[0] ||
    (currentVersionSplit[0] === targetVersionSplit[0] && currentVersionSplit[1] > targetVersionSplit[1]) ||
    (currentVersionSplit[0] === targetVersionSplit[0] &&
      currentVersionSplit[1] === targetVersionSplit[1] &&
      currentVersionSplit[2] >= targetVersionSplit[2])
  );
};
