export const STACK_NAVIGATION_UPDATE_VERSION = '1.2.0';
export const CONTACT_UPDATE_VERSION = '1.2.5';
export const CHANGE_URL_AFTER_STACK_POP_UPDATE_VERSION = '1.4.1';
export const PUSH_DEEPLINK_LOGIC_UPDATE_VERSION = '1.4.3';
export const LOCATION_UPDATE_VERSION = '1.4.5';
export const FONT_SIZE_UPDATE_VERSION = '1.4.9';
export const APP_IMAGE_PICKER_UPDATE_VERSION = '1.5.0';
export const FILE_DOWNLOAD_UPDATE_VERSION = '1.6.0';
export const THEME_TYPE_UPDATE_VERSION = '1.6.4';
export const LIMITED_CONTACT_UPDATE_VERSION = '1.6.5';
