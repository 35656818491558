import { SVGIconProps } from '../';

export default function VacancyIcon({ height = '24px', className, onClick, fill = '#000' }: SVGIconProps) {
  return (
    <svg
      className={className}
      height={height}
      onClick={onClick}
      viewBox={'0 0 24 24'}
      style={{ display: 'inline-block', verticalAlign: 'middle' }}
    >
      <rect x={'3'} y={'3'} width={'12'} height={'18'} rx={'1'} fill={fill} />
      <rect x={'13'} y={'10'} width={'8'} height={'11'} rx={'1'} fill={fill} />
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={'M8 5H5V6H8V5ZM8 9H5V10H8V9ZM16 17H18V18H16V17ZM18 13H16V14H18V13ZM5 13H8V14H5V13ZM11 18V17H5V18H11Z'}
        fill={'white'}
      />
    </svg>
  );
}
