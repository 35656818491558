import { useRouter } from 'next/router';

import { COLOR, COOKIE_KEY, LESSOR_ROUTES, NUMBER_OF_LESSEE_USERS_KOREAN_VERSION } from '@zaritalk/constants';
import { useCookie } from '@zaritalk/hooks/lib';
import { Tooltip, Typography } from '@zaritalk/ui-components/index';

export default function LessorBottomNavMyRoomTooltip() {
  const router = useRouter();
  const [, setNotShowingMyRoomItemTooltip] = useCookie(COOKIE_KEY.NOT_SHOWING_MY_ROOM_NAV_ITEM_TOOLTIP, '');

  return (
    <Tooltip
      onClick={() => {
        router.replace(LESSOR_ROUTES.MY_ROOM);
        setNotShowingMyRoomItemTooltip('true', { expires: 7 });
      }}
      tooltipStyle={{
        width: 'max-content',
        position: 'absolute',
        backgroundColor: COLOR.PRIMARY03,
        left: '50%',
        top: '-29px',
        transform: 'translateX(-50%)',
        borderRadius: '30px',
      }}
      containerStyle={{
        backgroundColor: COLOR.PRIMARY03,
        padding: '6px 8px',
        border: `1px solid ${COLOR.PRIMARY02}`,
        borderRadius: '30px',
      }}
      arrowStyle={{
        width: '8px',
        height: '8px',
        left: '48%',
        backgroundColor: COLOR.PRIMARY03,
        borderTop: `1px solid ${COLOR.PRIMARY02}`,
        borderLeft: `1px solid ${COLOR.PRIMARY02}`,
      }}
      direction={'bottom'}
      useClose={false}
      text={''}
    >
      <Typography variant={'11_MEDIUM_EXTRA_NARROW'} css={{ color: '$primary01' }}>
        {`${NUMBER_OF_LESSEE_USERS_KOREAN_VERSION}에게 무료 광고`}
      </Typography>
    </Tooltip>
  );
}
