import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { ZARITALK_TOKEN } from '@zaritalk/constants';
import { requestAnalyticsSetUserId, requestAnalyticsSetUserProperty } from '@zaritalk/protocol';
import { Nullable, ViewMode } from '@zaritalk/types';
import { getCookieItem } from '@zaritalk/utils/lib/cookie';

import { ApiReturn, getInitialData, getOffRefetchQueryOption, getWithToken } from '../../../utils';

export interface ILessorDetail {
  admin: boolean;
  deviceInfo: string;
  fcmToken: string;
  hostViewMode: ViewMode;
  inputRoomCount: Nullable<number>;
  lessorDetailPK: number;
  lessorPK: number;
  contactRegistered: boolean;
  tutorialCompleted: boolean;
  manageRoomCount: Nullable<number>;
  referer: string;
  signUpSource: Nullable<string>;
  typeformWritten: boolean;
  zaritalkRatingScore: Nullable<number>;
  vacancyRegistrationReminderExposedAt: Nullable<string>;
}

export const generateLessorDetailKey = (): string => {
  return `/lessor/detail`;
};

export const fetchLessorDetail = () => getWithToken<ILessorDetail>(generateLessorDetailKey());

export const useLessorDetail = () => {
  const authToken = process.env.LOGIN_TOKEN_NAME === ZARITALK_TOKEN ? getCookieItem(ZARITALK_TOKEN, '') : '';

  const { data, error, refetch, isLoading } = useQuery<ApiReturn<Nullable<ILessorDetail>>>(
    [generateLessorDetailKey()],
    fetchLessorDetail,
    {
      enabled: !!authToken,
      ...getOffRefetchQueryOption(),
      refetchOnWindowFocus: true,
      initialData: getInitialData<null>(null),
    },
  );

  useEffect(() => {
    if (data?.data) {
      const { lessorPK = -1, admin } = data.data;
      requestAnalyticsSetUserId({ id: String(lessorPK) });

      if (admin) {
        requestAnalyticsSetUserProperty({ name: 'isAdmin', value: String(admin) });
      }
    }
  }, [data]);

  return {
    data: data ? data.data : null,
    isLoading,
    error,
    refetch,
  };
};
