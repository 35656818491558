import type { AppType } from '../utils/slackReport';

interface IReportExclusion {
  httpMethod: string;
  includedUrl: string;
  statusCode?: number;
}

export const EXCLUDING_REPORTING_LIST: Array<IReportExclusion> = [
  {
    includedUrl: '/shortener/url',
    httpMethod: 'GET',
  },
];

export const TOKEN_MATCHED_APP_TYPE: { [token: string]: AppType } = {
  zaritalkToken: 'lessor',
  LESSEE_TOKEN: 'lessee',
  gongsilToken: 'realtor',
  COMMUNITY_TOKEN: 'community',
  ZARITALK_ADMIN_TOKEN: 'admin',
};
