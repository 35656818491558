import { memo, ReactElement } from 'react';

import { SVGIconProps } from '../';

const HouseIcon = memo(({ fill = '#222222', height = '24px', className, onClick }: SVGIconProps): ReactElement => {
  return (
    <svg
      width={height}
      height={height}
      className={className}
      onClick={onClick}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M12.769 3.2862C12.345 2.9046 11.656 2.9046 11.231 3.2862L3.545 10.2046C3.196 10.5187 3 10.9435 3 11.3872L3.1114 20.2008C3.1114 20.6418 3.5094 21 4.0004 21H10.1114L10 17.0518C10 16.5235 10.477 16.0942 11.064 16.0942H12.937C13.524 16.0942 14 16.5235 14 17.0518L14.1114 21C14.1114 21 16.1117 21 17.1118 21C18.149 21 20.2234 21 20.2234 21C20.7134 21 21 20.541 21 20.1V11.3872C21 10.9435 20.805 10.5187 20.456 10.2046L12.769 3.2862Z'
        }
        fill={fill}
      />
    </svg>
  );
});

export default HouseIcon;
