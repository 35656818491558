import Cookies, { CookieAttributes } from 'js-cookie';
import { GetServerSidePropsContext } from 'next';

import {
  JWT_TOKEN_EXPIRE_DAY,
  LESSEE_TOKEN,
  REALTOR_TOKEN,
  ZARITALK_SELECTED_MEMBER_TYPE,
  ZARITALK_TOKEN,
} from '@zaritalk/constants';
import { ZARITALK_MEMBER_TYPE } from '@zaritalk/types';
import { getExpiresDate } from '@zaritalk/utils/lib/date';

export const getCookies = (): { [key: string]: string } => {
  return Cookies.get() || {};
};

export const getCookieItem = (key: string, initialValue: any = ''): string => {
  return Cookies.get(key) || initialValue;
};

export const setCookieItem = (key: string, value: any, options?: CookieAttributes) => {
  Cookies.set(key, value, options);
};

export const removeCookieItem = (key: string, option = { path: '/', domain: '' }) => {
  Cookies.remove(key, option);
};

export const removeAMPTestCookie = () => {
  const ampTestCookieKey = 'amp_cookie_test';
  const option = { path: '/', domain: '' };
  const allCookie = getCookies();

  for (const cookieKey in allCookie) {
    if (cookieKey.includes(ampTestCookieKey)) {
      removeCookieItem(cookieKey, option);
    }
  }
};

const MEMBER_TYPE_MATCHED_TOKEN_KEY = {
  LESSOR: ZARITALK_TOKEN,
  LESSEE: LESSEE_TOKEN,
  REALTOR: REALTOR_TOKEN,
} as const;

interface ISSRUserLoginCookiesParams {
  memberType: ZARITALK_MEMBER_TYPE;
  token: string;
  ctx: GetServerSidePropsContext;
}

export const setSSRUserLoginCookies = ({ memberType, token, ctx }: ISSRUserLoginCookiesParams) => {
  const tokenExpires = getExpiresDate(JWT_TOKEN_EXPIRE_DAY);
  const threeYearExpires = getExpiresDate(365 * 3);
  const setToken = `${MEMBER_TYPE_MATCHED_TOKEN_KEY[memberType]}=${token}; path=/; expires=${tokenExpires}; sameSite=lax;`;
  const setMemberType = `${ZARITALK_SELECTED_MEMBER_TYPE}=${memberType}; path=/; domain=.zaritalk.com; expires=${threeYearExpires}; sameSite=lax;`;
  ctx.res.setHeader('Set-Cookie', [setToken, setMemberType]);
};

export const cookieStringToObject = (cookieString: string) => {
  const cookies = cookieString.split('; ');

  const cookieObject = cookies.reduce((acc, cookieString) => {
    const [key, value] = cookieString.split('=');
    return { ...acc, [key]: value };
  }, {});

  return cookieObject;
};

export const getSSRCookie = (ctx: GetServerSidePropsContext, key: string) => {
  const cookies = cookieStringToObject(ctx.req.headers.cookie || '');
  return cookies[key];
};
