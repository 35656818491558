import { useEffect } from 'react';

import { useLessorDetail, useModifyLessorDetail } from '@zaritalk/api';
import { ZARITALK_APP_INSTALLED } from '@zaritalk/constants';
import { isApp } from '@zaritalk/utils/index';

const useLessorRefererCheckAndUpdate = () => {
  const { data: lessorDetail, isLoading } = useLessorDetail();
  const { mutateAsync: updateLessorDetail } = useModifyLessorDetail();

  useEffect(() => {
    if (!isApp() || isLoading || !lessorDetail) return;

    lessorDetail.referer !== ZARITALK_APP_INSTALLED && updateLessorDetail({ referer: ZARITALK_APP_INSTALLED });
  }, [lessorDetail, isLoading, updateLessorDetail]);

  return { referer: lessorDetail?.referer ?? null };
};

export default useLessorRefererCheckAndUpdate;
