import { ERROR_MSG } from '@zaritalk/constants';

import { ApiError } from './http';

export const getOffRefetchQueryOption = (staled = false) => {
  return {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: staled ? Infinity : 0,
  };
};

export const getApiErrorMessage = (error: ApiError, defaultMessage?: string) => {
  const errorMessage = error?.response?.data?.message ?? '';
  return ERROR_MSG[errorMessage] ?? defaultMessage;
};
