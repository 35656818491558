import { ReactElement } from 'react';

import { requestSlackErrorReport } from '@zaritalk/utils/index';

import DefaultErrorComponent from './DefaultErrorComponent';
import { ErrorBoundary } from './ErrorBoundary';

export default function GlobalErrorBoundary({ children }: { children: ReactElement }) {
  return (
    <ErrorBoundary
      fallbackRender={() => <DefaultErrorComponent />}
      onError={(error) => requestSlackErrorReport(error, 'global')}
    >
      {children}
    </ErrorBoundary>
  );
}
