import { IBuildingFormInfo } from './contact';

export type Nullable<T> = T | null;

export type MEMBER_TYPE = 'LESSOR' | 'LESSEE' | 'REALTOR';
export type BadgeType =
  | 'UNPAID' /*미납*/
  | 'PAID_PARTIAL' /*부분납부*/
  | 'PAID_ALL' /*납부*/
  | 'SCHEDULED'; /*납부예정*/

export interface Image {
  filePath: string;
  filePK: number;
}

export interface IUploadedImage {
  filePK: number | string;
  filePath: string;
  file?: File;
  state: 'loading' | 'loaded';
}

export enum LESSOR_STATUS {
  DEPOSIT = 'deposit',
  EXPECTED = 'expected',
  UNPAID = 'unpaid',
}

export enum LEASE_TYPE {
  PREPAID_MONTHLY = 'PREPAID_MONTHLY', //(월세선불)
  POSTPAID_MONTHLY = 'POSTPAID_MONTHLY', //(월세후불)
  JEONSE = 'JEONSE', //(전세)
  EMPTY = 'EMPTY', //(공실)
}

export enum VAT {
  NONE = 'NONE', //(없음)
  MONTHLY = 'MONTHLY', //(임대료)
  MAINTENANCE = 'MAINTENANCE', //(관리비)
  MONTHLY_AND_MAINTENANCE = 'MONTHLY_AND_MAINTENANCE', //(임대료+관리비)MONTHLY
}

export type STATUS = 'LIVING' /*(거주중)*/ | 'STAND_BY' /*(입주대기)*/ | 'MOVE_OUT'; /*(퇴거완료)*/
export type PaymentType =
  | 'UNPAID' /*미납*/
  | 'PAID_PARTIAL' /*부분납부*/
  | 'PAID_ALL' /*납부*/
  | 'SCHEDULED'; /*납부예정*/

// 임차인
export interface Lessee {
  PK: number;
  phoneNum: string;
  createdAt: string;
  updatedAt: string;
}

// 임대인
export interface Lessor {
  PK: number;
  phoneNum: string;
  name: string;
  kakaoId: number;
  nickname: string;
  email: string;
  birthYear: string;
  thumbnailImg: string;
  createdAt: string;
  updatedAt: string;
}

export interface IAddresses {
  address: string;
  landLotBasedAddress: string;
  roadNameBasedAddress: string;
}

export interface IAddressInfo {
  landLotBasedAddress: string;
  roadNameBasedAddress: string;
  latitude: string | number;
  longitude: string | number;
  name: string;
}

export interface Building {
  pk: number;
  addresses: IAddresses;
  latitude: number;
  longitude: number;
  createdAt: number;
}

export interface BuildingInfo {
  lessorBuildingPk: number;
  buildingName: string;
  buildingType: string | null;
  rooms: Array<RoomInfo>;
}

export interface RoomInfo {
  roomPk: number;
  leaseType: string;
  roomName: string;
}

export interface LessorBuilding {
  PK: number;
  lessor: Lessor;
  building: Building;
  name: string;
  lessorBuildingRooms: Array<LessorBuildingRoom>;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface LessorBuildingRoom {
  PK: number;
  lessorBuilding: LessorBuilding;
  name: string;
  leaseType: LEASE_TYPE;
  lessor: Lessor;
  contract: Contract;
  status: STATUS;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface Contract {
  pk: number;
  lessorBuildingRoom: LessorBuildingRoom;
  leaseType: LEASE_TYPE;
  lesseeName: string;
  lessee: Lessee;
  lessor: Lessor;
  status: STATUS;
  contractStartDate: string;
  contractEndDate: string;
  moveInDate: string;
  moveOutDate: string;
  deposit: number;
  monthlyRent: number;
  maintenanceFee: number;
  vat: VAT;
  monthlyRentPaymentDate: number;
  overdueMonth: number;
  createdAt: string;
  updatedAt: string;
}

export interface Guest {
  buildingInfo: IBuildingFormInfo;
  leaseType: LEASE_TYPE | string | null;

  roomName: string;
  lesseeName: string;
  lesseePhoneNum: string;

  contractStartDate: string;
  contractEndDate: string;

  deposit: Nullable<string | number>;
  monthlyRent: Nullable<string | number>;
  maintenanceFee: Nullable<string | number>;

  monthlyRentPaymentDate: Nullable<string | number>;
  overdueMonth: Nullable<string | number>;
  vat: VAT;

  disabledMaintenanceFee?: boolean;
  previousContractPk?: number;
}

export interface GuestProps extends Guest {
  buildingName: string;
  pk: number;

  dispatch?: (key: any, value: any) => void | undefined;
}

export type BuildingOptionOrderBy = 'PAYMENT_DATE' | 'ROOM';
export type ViewMode = 'BUILDING' | 'LESSEE';

export enum ROOM_TAP_ITEMS {
  CONTRACT = '계약/수납',
  MEMO = '호실 메모',
}

export type ROOM_TAP_TYPE = keyof typeof ROOM_TAP_ITEMS;

export enum ActionType {
  SIGN_UP = 'SIGN_UP',
  WITHDRAWAL = 'WITHDRAWAL',
  VISIT = 'VISIT',
  REGISTER_BUILDING = 'REGISTER_BUILDING',
  DELETE_BUILDING = 'DELETE_BUILDING',
  REGISTER_LESSEE = 'REGISTER_LESSEE',
  DELETE_LESSEE = 'DELETE_LESSEE',
  COMPLETED_PAID = 'COMPLETED_PAID',

  REGISTER_VACANCY_ROOM = 'REGISTER_VACANCY_ROOM', // 방 내놓기 등록
  KEEP_REGISTER_VACANCY_ROOM = 'KEEP_REGISTER_VACANCY_ROOM', // 이어서 입력하기 클릭
  REGISTER_VACANCY_ROOM_DETAIL = 'REGISTER_VACANCY_ROOM_DETAIL', // 방 내놓기 상세보기

  WEB_SHARE_API_FREE_TICKET = 'WEB_SHARE_API_FREE_TICKET', // 방 내놓기 상세보기
  WEB_SHARE_API_PETITION = 'WEB_SHARE_API_PETITION', // 국회청원 공유하기 클릭

  PETITION_HOST_BANNER = 'PETITION_HOST_BANNER', // 방 내놓기 상세보기

  MASS_REGISTRATION_BUTTON_CLICK = 'MASS_REGISTRATION_BUTTON_CLICK', // 대량등록 버튼 클릭

  HOST_PAGE_EMPTY_TAG_CLICK = 'HOST_PAGE_EMPTY_TAG_CLICK', // 임대장부화면 방내놓기 태그 클릭

  // 반복일정
  REGISTER_RECURRING_SCHEDULE = 'REGISTER_RECURRING_SCHEDULE', // 반복일정 등록 완료
  DELETE_RECURRING_SCHEDULE = 'DELETE_RECURRING_SCHEDULE', // 반복일정 삭제 완료

  // 푸시
  PUSH_COMMUNITY_DETAIL = 'PUSH_COMMUNITY_DETAIL',
  PUSH_COMMUNITY_DETAIL_COMMENT = 'PUSH_COMMUNITY_DETAIL_COMMENT',
  PUSH_COMMUNITY_NEW_POST = 'PUSH_COMMUNITY_NEW_POST',
  PUSH_COMMUNITY_LIST_CATEGORY = 'PUSH_COMMUNITY_LIST_CATEGORY',

  TALK_VIEW = 'TALK_VIEW',
}

export interface PaymentInputData {
  paymentMonth: string;

  localDate: string; // 수납입자
  paidMonthlyRent: number; // 임대료
  paidMaintenanceFee: number; // 관리비

  paidAmount: number; // 총 납부액
  paidVatFee: number; // 부가세
  memo: string; // 메모

  paymentType: BadgeType; // 수납 타입
}

export type SubAccountPhoneNumber = {
  pk: number;
  phoneNumber: string;
};

export type LeaseAccountBookAppClipKind = 'ZARITALK_HOST_ACTION_APP_INSTALL_CLIP';
export type MyRoomAppClipKind = 'ZARITALK_ROOM_ACTION_APP_INSTALL_CLIP';

export enum PUSHES {
  BENEFITS_AND_USEFUL_INFORMATION = 'BENEFITS_AND_USEFUL_INFORMATION',
  COMMUNITY_POPULAR_POST = 'COMMUNITY_POPULAR_POST',
  COMMUNITY_RECOMMENDED_NEW_POST = 'COMMUNITY_RECOMMENDED_NEW_POST',
  COMMUNITY_COMMENT_ALERT = 'COMMUNITY_COMMENT_ALERT',
  REAL_ESTATE_PRICES_UPDATE = 'REAL_ESTATE_PRICES_UPDATE',
  REAL_RESIDENCE_REVIEW_UPDATE = 'REAL_RESIDENCE_REVIEW_UPDATE',
}

export type TPushType = keyof typeof PUSHES;

export enum ALIMTALKS {
  BENEFITS_AND_USEFUL_INFORMATION = 'BENEFITS_AND_USEFUL_INFORMATION',
  HOST = 'HOST',
  VACANCY = 'VACANCY',
}

export type TAlimtalkType = keyof typeof ALIMTALKS;

export interface INotification {
  push: Array<{ name: TPushType; enabled: boolean }>;
  alimtalk: Array<{ name: TAlimtalkType; enabled: boolean }>;
}

export type DateFormatType = 'sec' | 'min' | 'hour' | 'day';

export type RadioItem = {
  value: string;
  label: string;
};

export interface ILessorContact {
  pk: number;
  lessorBuildingPk: number;
  lesseeName: string;
  lesseePhoneNumber: string;
  monthlyRentPaymentDate: Nullable<number>;
}

export interface IOrderFile {
  filePk: number;
  ordering: number;
}
