import { HTMLAttributes, PropsWithChildren } from 'react';

import { TYPOGRAPHY_VARIANTS } from '@zaritalk/constants';
import { styled } from '@zaritalk/panda-css/jsx';
import { StyledVariantProps, SystemStyleObject } from '@zaritalk/panda-css/types';
import { KeyOf } from '@zaritalk/types';

interface TypographyProps
  extends PropsWithChildren,
    StyledVariantProps<typeof StyledTypography>,
    HTMLAttributes<HTMLSpanElement> {
  variant: KeyOf<typeof TYPOGRAPHY_VARIANTS>;
  css?: SystemStyleObject | SystemStyleObject[];
  underline?: boolean;
}

export default function Typography({ variant, children, underline, css, ...restProps }: TypographyProps) {
  return (
    <StyledTypography variant={variant} underline={underline} css={css} {...restProps}>
      {children}
    </StyledTypography>
  );
}

const StyledTypography = styled(
  'span',
  {
    base: {
      display: 'inline-block',
    },

    variants: {
      underline: {
        true: {
          textDecoration: 'underline',
        },
      },
      variant: TYPOGRAPHY_VARIANTS,
    },
  },
  {
    defaultProps: {
      underline: false,
    },
  },
);
