import axios, { AxiosError } from 'axios';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { getWindow } from 'ssr-window';

import { STATUS_CODE } from '@zaritalk/constants';
import { requestSlackErrorReport } from '@zaritalk/utils/index';

export const clientSideApiErrorHandler = (err: any) => {
  const catchError = err as Error | AxiosError;

  if (axios.isAxiosError(catchError)) {
    const status = parseInt(String(catchError?.response?.status ?? ''));
    const message = err?.response?.data?.message ?? '';
    const isNotFoundUser =
      message.includes('not found lessor') ||
      message.includes('not found lessee') ||
      message.includes('not found realtor');

    if (status === STATUS_CODE.UNAUTHORIZED || isNotFoundUser) {
      return getWindow().location.replace(`/logout?next=${encodeURIComponent(getWindow().location.href)}`);
    }

    // captureException(err.error);
  } else {
    // captureException(err);
  }
};

export const serverSideErrorHandler = (
  error: unknown,
  ctx: GetServerSidePropsContext,
  destination?: string,
): GetServerSidePropsResult<any> => {
  const catchError = error;

  if (axios.isAxiosError(catchError)) {
    const status = catchError.status
      ? parseInt(String(catchError.status))
      : parseInt(String(catchError.response.status));

    if (status === STATUS_CODE.NOT_FOUND) {
      return {
        redirect: {
          destination: '/404',
          permanent: false,
        },
      };
    }

    if (status === STATUS_CODE.UNAUTHORIZED) {
      return {
        redirect: {
          destination: '/logout',
          permanent: false,
        },
      };
    }

    requestSlackErrorReport(error as Error, 'serverside', ctx);

    return destination
      ? {
          redirect: {
            destination,
            permanent: false,
          },
        }
      : {
          props: {},
        };
  } else {
    requestSlackErrorReport(error as Error, 'serverside', ctx);

    return destination
      ? {
          redirect: {
            destination,
            permanent: false,
          },
        }
      : {
          props: {},
        };
  }
};
