import { useQuery } from '@tanstack/react-query';

import { ZARITALK_TOKEN } from '@zaritalk/constants';
import { ILessorContact } from '@zaritalk/types';
import { getCookieItem } from '@zaritalk/utils/lib/cookie';

import { ApiReturn, getOffRefetchQueryOption, getWithToken } from '../../utils';

export const generateGetLessorContactsKey = () => {
  return '/lessor/contact';
};

export const fetchGetLessorContacts = () => {
  return getWithToken<Array<ILessorContact>>(generateGetLessorContactsKey());
};

export const useGetLessorContacts = () => {
  const authToken = process.env.LOGIN_TOKEN_NAME === ZARITALK_TOKEN ? getCookieItem(ZARITALK_TOKEN, '') : '';

  const { data, error, isLoading } = useQuery<ApiReturn<Array<ILessorContact>>>(
    [generateGetLessorContactsKey()],
    fetchGetLessorContacts,
    {
      enabled: !!authToken,
      ...getOffRefetchQueryOption(),
    },
  );

  return {
    data: data?.data ?? [],
    isLoading,
    error,
  };
};
