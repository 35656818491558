import { COLOR } from '@zaritalk/constants';

import { SVGIconProps } from '..';

interface SpeechBubbleLeftProps extends SVGIconProps {}

export default function SpeechBubbleLeft({
  fill = COLOR.BLACK,
  height = '24px',
  className,
  onClick,
}: SpeechBubbleLeftProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      width={height}
      height={height}
      viewBox={'0 0 24 24'}
      fill={'none'}
      className={className}
      onClick={onClick}
    >
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M12.5 3C7.25305 3 3.00005 6.806 3.00005 11.5C3.00005 12.936 3.40105 14.287 4.10405 15.473L2.05905 20.074C1.81404 20.624 2.37605 21.186 2.92605 20.941L7.69305 18.822C9.10405 19.566 10.744 20 12.5 20C17.747 20 22 16.194 22 11.5C22 6.806 17.747 3 12.5 3Z'
        }
        fill={fill}
      />
    </svg>
  );
}
