const REST_API_URL = {
  KAKAO_JOIN: '/account/join/oauth/kakao',
  JOIN_LESSOR: '/account/lessor/join',
  LOGIN_LESSOR: '/account/lessor/login',
  QUIT_LESSOR: '/account/lessor/quit',
  SEND_CERTIFICATION_NUM: '/auth/cert-number',

  ACTIVITY_LOG: '/logs/activity', // 사용자 활동 로그
};

export default REST_API_URL;
