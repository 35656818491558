import { styled } from '@zaritalk/panda-css/jsx';

const SmallButton = styled(
  'button',
  {
    base: {
      flexCol: 'CENTER',

      typography: '16_BOLD_MIDDLE',
      textAlign: 'center',

      transition: 'background-color 0.3s, color 0.3s, opacity 0.1s',
      outline: 'none',

      backgroundColor: '$white',
      borderRadius: '4px',
      border: 'solid 1px token(colors.$gray03)',

      cursor: 'pointer',
      userSelect: 'none',

      margin: 0,
      padding: '4px',

      '&:hover, :active': {
        opacity: 0.8,
      },
    },

    variants: {
      variant: {
        PRIMARY: {
          backgroundColor: '$primary01',
          border: 'solid 1px token(colors.$primary01)',

          color: '$white',
          cursor: 'pointer',

          '&:active': {
            opacity: 0.8,
          },
        },
        SECONDARY: {
          backgroundColor: '$white',
          border: 'solid 1px token(colors.$gray03)',

          color: '$gray05',
          cursor: 'pointer',

          '&:active': {
            opacity: 0.8,
          },
        },
        THIRD: {
          backgroundColor: '$primary02',

          color: '$primary01',
          cursor: 'pointer',

          '&:active': {
            opacity: 0.8,
          },
        },
        WARNING: {
          backgroundColor: '$white',
          border: 'solid 1px token(colors.$red)',

          padding: '10px 28px',
          fontSize: '12px',
          fontWeight: 'bold',
          color: '$red',
          cursor: 'pointer',

          '&:active': {
            opacity: 0.8,
          },
        },
      },
      disabled: {
        true: {
          backgroundColor: '$gray02',
          border: 'solid 1px token(colors.$gray02)',

          color: '$gray04',
          cursor: 'none',
        },
      },
      position: {
        TOP: {
          borderTopRightRadius: '8px',
          borderTopLeftRadius: '8px',
        },
        BOTTOM: {
          borderBottomRightRadius: '8px',
          borderBottomLeftRadius: '8px',
        },
        LEFT: {
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
        },
        RIGHT: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
        },
        CENTER: {
          borderRadius: 0,
        },
        DEFAULT: {
          borderRadius: '8px',
        },
      },
    },
  },
  {
    defaultProps: {
      variant: 'PRIMARY',
      position: 'DEFAULT',
    },
  },
);

export default SmallButton;
