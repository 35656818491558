import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

import { RESULTS } from '@zaritalk/protocol';
import { IContact, ILessorContact } from '@zaritalk/types';

type ContactPopupType =
  | 'register_lessee_complete'
  | 'add_lessee_complete'
  | 'lessee_certification_guide'
  | 'real_lessee_confirmation'
  | 'lessee_selection_limit';

const contactStateAtom = atom<Array<IContact>>([]);
const contactPermissionStateAtom = atom<RESULTS>('empty');
const selectedContactStateAtom = atom<Array<IContact>>([]);
const contactSearchStateAtom = atom<string>('');
const contactPopupTypeStateAtom = atom<ContactPopupType | ''>('');
const contactLesseeStateAtom = atom<Array<ILessorContact>>([]);
const clickAutoSelectionStateAtom = atom<boolean>(false);
const autoSelectContactStateAtom = atom<Array<IContact>>([]);
export const sourcePageAtom = atom<'community' | ''>('');

export function useContactState() {
  const [contactsPermission, setContactsPermission] = useAtom(contactPermissionStateAtom);
  const [contactList, setContactList] = useAtom(contactStateAtom);
  const [selectedContactList, setSelectedContactList] = useAtom(selectedContactStateAtom);
  const [contactSearchValue, setContactSearchValue] = useAtom(contactSearchStateAtom);
  const [contactPopupType, setContactPopupType] = useAtom(contactPopupTypeStateAtom);
  const [contactLesseeList, setContactLesseeList] = useAtom(contactLesseeStateAtom);
  const [clickAutoSelection, setClickAutoSelection] = useAtom(clickAutoSelectionStateAtom);
  const [autoSelectContactList, setAutoSelectContactList] = useAtom(autoSelectContactStateAtom);

  const allClearContactState = useCallback(() => {
    setSelectedContactList([]);
    setContactSearchValue('');
    setContactPopupType('');
    setContactLesseeList([]);
    setClickAutoSelection(false);
  }, [setSelectedContactList, setContactSearchValue, setContactPopupType, setContactLesseeList, setClickAutoSelection]);

  return {
    contactList,
    setContactList,
    contactsPermission,
    setContactsPermission,
    selectedContactList,
    setSelectedContactList,
    contactSearchValue,
    setContactSearchValue,
    contactPopupType,
    setContactPopupType,
    contactLesseeList,
    setContactLesseeList,
    clickAutoSelection,
    setClickAutoSelection,
    autoSelectContactList,
    setAutoSelectContactList,
    allClearContactState,
  };
}
