import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

interface IGlobalAlertState {
  alertText: string;
  alertSubText: string;
  contents: JSX.Element | null;
  alertCallback: VoidFunction;
}

const globalAlertStateAtom = atom<IGlobalAlertState>({
  alertText: '',
  alertSubText: '',
  contents: null,
  alertCallback: () => null,
});

const globalAlertTextAtom = atom<string, [newValue: string], void>(
  (get) => get(globalAlertStateAtom).alertText,
  (get, set, newValue: string) => {
    const prevData = get(globalAlertStateAtom);
    set(globalAlertStateAtom, { ...prevData, alertText: newValue });
  },
);

const globalAlertSubTextAtom = atom<string, [newValue: string], void>(
  (get) => get(globalAlertStateAtom).alertSubText,
  (get, set, newValue: string) => {
    const prevData = get(globalAlertStateAtom);
    set(globalAlertStateAtom, { ...prevData, alertSubText: newValue });
  },
);

const globalAlertContentsAtom = atom<JSX.Element | null, [newAlertContents: JSX.Element | null], void>(
  (get) => get(globalAlertStateAtom).contents,
  (get, set, newAlertContents: JSX.Element | null) => {
    const prevData = get(globalAlertStateAtom);
    set(globalAlertStateAtom, { ...prevData, contents: newAlertContents });
  },
);

const globalAlertCallbackAtom = atom<VoidFunction, [newAlertCallback: VoidFunction], void>(
  (get) => get(globalAlertStateAtom).alertCallback,
  (get, set, newAlertCallback: VoidFunction) => {
    const prevData = get(globalAlertStateAtom);
    set(globalAlertStateAtom, { ...prevData, alertCallback: newAlertCallback });
  },
);

export function useAlertState() {
  const [alertSubText, setAlertSubText] = useAtom(globalAlertSubTextAtom);
  const [alertText, setAlertText] = useAtom(globalAlertTextAtom);
  const [alertContents, setAlertContents] = useAtom(globalAlertContentsAtom);
  const [alertCallback, setAlertCallback] = useAtom(globalAlertCallbackAtom);

  const setAlertState = useCallback(
    ({ text, subText, callback }: { text: string; subText?: string; callback?: VoidFunction }) => {
      setAlertText(text);
      subText && setAlertSubText(subText);
      callback && setAlertCallback(callback);
    },
    [],
  );
  const resetAlertCallback = useCallback(() => setAlertCallback(() => null), []);

  return {
    alertSubText,
    setAlertSubText,
    alertText,
    setAlertText,
    alertContents,
    setAlertContents,
    setAlertState,
    alertCallback,
    setAlertCallback,
    resetAlertCallback,
  };
}
