import axios, { AxiosError } from 'axios';

import { ApiReturn } from '../../';

// @ts-ignore
const UnknownError: ApiReturn = { data: null, message: 'unknown error', status: 400 };

export function axiosErrorHandler<T>(callback: (err: Error | AxiosError<T>) => T) {
  return (error: Error | AxiosError<T>) => {
    if (axios.isAxiosError(error) && (error.code === 'ECONNABORTED' || axios.isCancel(error))) {
      console.warn('Axios request canceled');
      return UnknownError;
    }

    if (!error) {
      return callback(UnknownError);
    }

    return callback(error);
  };
}
