import { COLOR } from '@zaritalk/constants';

import { SVGIconProps } from '../';

function NavSearchIcon({ fill = COLOR.BLACK, height = '24px', className, onClick }: SVGIconProps) {
  return (
    <svg
      className={className}
      height={height}
      fill={'none'}
      onClick={onClick}
      viewBox={'0 0 24 24'}
      style={{ display: 'inline-block', verticalAlign: 'middle' }}
    >
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M15.292 10.2307C15.292 13.0718 13.0031 15.3749 10.1797 15.3749C7.35624 15.3749 5.06738 13.0718 5.06738 10.2307C5.06738 7.38967 7.35624 5.08653 10.1797 5.08653C13.0031 5.08653 15.292 7.38967 15.292 10.2307ZM14.8506 16.9884C13.5263 17.917 11.9162 18.4615 10.1797 18.4615C5.66217 18.4615 2 14.7764 2 10.2307C2 5.68503 5.66217 2 10.1797 2C14.6972 2 18.3594 5.68503 18.3594 10.2307C18.3594 11.9136 17.8574 13.4786 16.996 14.7822L21.5508 19.3655C22.1497 19.9682 22.1497 20.9453 21.5508 21.548C20.9518 22.1507 19.9808 22.1507 19.3818 21.548L14.8506 16.9884Z'
        }
        fill={fill}
      />
    </svg>
  );
}

export default NavSearchIcon;
