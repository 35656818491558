import { GetServerSidePropsContext } from 'next';

export function isSSR() {
  return typeof window === 'undefined';
}

export const getUserAgent = (ctx?: GetServerSidePropsContext): string => {
  return isSSR() && !!ctx ? getServerSideUserAgent(ctx) : getClientSideUserAgent();
};

export const getClientSideUserAgent = (): string => {
  if (isSSR()) {
    console.warn('Please use getServerSideUserAgent or getUserAgent function');
    return '';
  } else {
    return window.navigator.userAgent;
  }
};

export const getServerSideUserAgent = (ctx: GetServerSidePropsContext): string => {
  return ctx?.req?.headers['user-agent'] ?? '';
};
