import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { GetServerSidePropsContext } from 'next';

import { API_URL, ZARITALK_DEV_API } from '@zaritalk/constants';
import { noop } from '@zaritalk/utils/lib/operation';

import { ApiReturn, slackReportFetcher, checkAuthToken } from '../../index';
import { axiosErrorHandler } from './errorHanleMiddleware';

export const getInitialData = <T>(data: T): ApiReturn<T> => {
  return {
    data: data,
    message: '',
    status: 200,
  };
};

export const getNoMessageWithToken = async <T>(url: string, ctx?: GetServerSidePropsContext): Promise<T> => {
  const config = checkAuthToken({ req: ctx?.req ?? undefined }) as AxiosRequestConfig;

  try {
    const { data: resData } = await axios.get<T, AxiosResponse<T>>(`${API_URL}${url}`, config);
    return resData;
  } catch (err) {
    const catchError = err as Error | AxiosError<T>;
    return axiosErrorHandler<T>((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<T>;
        slackReportFetcher('GET', url, {}, response, ctx).catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};

export const getWithToken = async <T>(url: string, ctx?: GetServerSidePropsContext): Promise<ApiReturn<T>> => {
  const config = checkAuthToken({ req: ctx?.req ?? undefined }) as AxiosRequestConfig;

  try {
    const { data: resData } = await axios.get<T, AxiosResponse<ApiReturn<T>>>(`${API_URL}${url}`, config);
    return resData;
  } catch (err) {
    const catchError = err as Error | AxiosError<T>;
    return axiosErrorHandler<ApiReturn<T>>((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<ApiReturn<T>>;
        slackReportFetcher('GET', url, {}, response, ctx).catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};

export const getWithoutToken = async <T>(url: string): Promise<ApiReturn<T>> => {
  try {
    const res = await axios.get<T, AxiosResponse<ApiReturn<T>>>(`${API_URL}${url}`);
    return res.data;
  } catch (err) {
    const catchError = err as Error | AxiosError<T>;
    return axiosErrorHandler<ApiReturn<T>>((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<ApiReturn<T>>;
        slackReportFetcher('GET', url, {}, response).catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};

export const getInternalAPI = async <T>(url: string): Promise<ApiReturn<T>> => {
  try {
    const res = await axios.get<T, AxiosResponse<ApiReturn<T>>>(url);
    return res.data;
  } catch (err) {
    const catchError = err as Error | AxiosError<T>;
    return axiosErrorHandler<ApiReturn<T>>((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<ApiReturn<T>>;
        slackReportFetcher('GET', url, {}, response, undefined, 'internal').catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};

export const getExternalAPI = async <T>(url: string): Promise<ApiReturn<T>> => {
  try {
    const res = await axios.get<T, AxiosResponse<ApiReturn<T>>>(url);
    return res.data;
  } catch (err) {
    const catchError = err as Error | AxiosError<T>;
    return axiosErrorHandler<ApiReturn<T>>((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<ApiReturn<T>>;
        slackReportFetcher('GET', url, {}, response, undefined, 'external').catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};

export const getExternalAPIWithToken = async <T>(url: string, token: string, config = {}) => {
  try {
    const res = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}`, ...config },
    });
    return res.data;
  } catch (err) {
    const catchError = err as Error | AxiosError;
    return axiosErrorHandler((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<ApiReturn<T>>;
        slackReportFetcher('GET', url, {}, response, undefined, 'external').catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};

export const getDevWithToken = async <T>(url: string, ctx?: GetServerSidePropsContext): Promise<ApiReturn<T>> => {
  const config = checkAuthToken({ req: ctx?.req ?? undefined }) as AxiosRequestConfig;

  try {
    const { data: resData } = await axios.get<T, AxiosResponse<ApiReturn<T>>>(`${ZARITALK_DEV_API}${url}`, config);
    return resData;
  } catch (err) {
    const catchError = err as Error | AxiosError<T>;
    return axiosErrorHandler<ApiReturn<T>>((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<ApiReturn<T>>;
        slackReportFetcher('GET', url, {}, response, ctx).catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};

export const getMockWithoutToken = async <T>(url: string): Promise<ApiReturn<T>> => {
  try {
    const res = await axios.get<T, AxiosResponse<ApiReturn<T>>>(url);
    return res.data;
  } catch (err) {
    const catchError = err as Error | AxiosError<T>;
    return axiosErrorHandler<ApiReturn<T>>((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<ApiReturn<T>>;
        slackReportFetcher('GET', url, {}, response).catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};
