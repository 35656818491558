import { useGetLessorContacts, useLessorDetail, useMonthlyPaymentHistories } from '@zaritalk/api';
import { IBuildingInfo } from '@zaritalk/types';

const MIN_ROOM_CAPACITY_COUNT = 5;

const getContractsCount = (paymentHistories: IBuildingInfo[]) => {
  const filteredLesseeContractHisList = paymentHistories
    .flatMap(({ roomInfoList }) => roomInfoList)
    .flatMap(({ lesseeContractHisList }) => lesseeContractHisList);

  const totalCount = filteredLesseeContractHisList.reduce((current, { lesseePhoneNum }) => {
    if (lesseePhoneNum) {
      return current + 1;
    }
    return current;
  }, 0);

  return totalCount;
};

export default function useConfirmPaymentDateCertification() {
  const lessorContacts = useGetLessorContacts();
  const paymentHistories = useMonthlyPaymentHistories();
  const lessorDetail = useLessorDetail();

  const checkFulfillment = (roomCount: number) => {
    if (roomCount === 0) return false;

    if (roomCount >= MIN_ROOM_CAPACITY_COUNT) {
      return totalLength >= MIN_ROOM_CAPACITY_COUNT;
    } else {
      return totalLength >= roomCount;
    }
  };

  const filteredContacts = lessorContacts.data.filter(({ monthlyRentPaymentDate }) => monthlyRentPaymentDate !== null);
  const totalLength = filteredContacts.length + getContractsCount(paymentHistories.data);
  const isFulfilled = checkFulfillment(lessorDetail.data?.inputRoomCount ?? 0);

  return {
    isFulfilled,
    checkFulfillment,
    isAllDataLoaded: !lessorContacts.isLoading && !paymentHistories.isLoading && !lessorDetail.isLoading,
  };
}
