import { useEffect, useMemo, useState } from 'react';

import { DEVICE_TYPE } from '@zaritalk/constants';

interface UserDevice {
  userDevice: string;
  isPC: boolean;
  isAndroid: boolean;
  isIphone: boolean;
  isKakaoSafeArea: boolean;
  isKakaoWebView: boolean;
  isApp: () => boolean;
}

const useUserDevice = (): UserDevice => {
  const userAgent = useMemo(() => {
    if (typeof window !== 'undefined') {
      return window?.navigator.userAgent.toLowerCase() ?? '';
    }
    return '';
  }, []);
  const [userDevice, setUserDevice] = useState<string>('');
  const [isPC, setPC] = useState<boolean>(false);
  const [isAndroid, setAndroid] = useState<boolean>(false);
  const [isIphone, setIphone] = useState<boolean>(false);
  const [isKakaoSafeArea, setKakaoSafeArea] = useState<boolean>(false);
  const [isKakaoWebView, setKakaoWebView] = useState<boolean>(false);

  const isApp = () => {
    // @ts-ignore
    return typeof window !== 'undefined' && !!window.isZaritalkApp;
  };

  useEffect(() => {
    if (userAgent.match(/android\s([0-9.]*)/) !== null) {
      setUserDevice(DEVICE_TYPE.ANDROID);
      setAndroid(true);
    } else if (userAgent.match(/iphone\s([0-9.]*)/) !== null) {
      setUserDevice(DEVICE_TYPE.IPHONE);
      setIphone(true);

      if (userAgent.match(/kakaotalk\s([0-9.]*)/) !== null) {
        setKakaoSafeArea(true);
      }
    } else {
      setUserDevice(DEVICE_TYPE.WEB);
      setPC(true);
    }
    if (userAgent.match(/kakao/) !== null) {
      setKakaoWebView(true);
    }
  }, [userAgent]);

  return { userDevice, isPC, isAndroid, isIphone, isKakaoSafeArea, isApp, isKakaoWebView };
};

export default useUserDevice;
