import { atom, useAtom } from 'jotai';

export const bottomNavigationStateAtom = atom<boolean>(false);

export function useNavigationState() {
  const [isShowBottomNavigation, setIsShowBottomNavigation] = useAtom(bottomNavigationStateAtom);

  return {
    isShowBottomNavigation,
    setIsShowBottomNavigation,
  };
}
