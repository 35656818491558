export const debounce = <Params extends unknown[]>(callback: (...args: Params) => unknown, delayMs: number) => {
  let timeoutId: NodeJS.Timeout;

  return (...args: Params) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      callback(...args);
    }, delayMs);
  };
};
