import { atom, useAtom } from 'jotai';

const currentDateStateAtom = atom<Date>(new Date());

export function useCurrentDateState() {
  const [currentDate, setCurrentDate] = useAtom(currentDateStateAtom);

  return {
    currentDate,
    setCurrentDate,
  };
}
