import { useAtom } from 'jotai';

import { sourcePageAtom } from '../state/contactState';

const useCommunityPopupEntryCheck = () => {
  const [currentSourcePage, setSourcePage] = useAtom(sourcePageAtom);

  const isSourcePageCommunity = currentSourcePage === 'community';

  const setIsSourcePageCommunity = () => {
    setSourcePage('community');
  };

  const removeSourcePage = () => {
    setSourcePage('');
  };

  return {
    isSourcePageCommunity,
    setIsSourcePageCommunity,
    removeSourcePage,
  };
};
export default useCommunityPopupEntryCheck;
