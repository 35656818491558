import { AxiosRequestConfig } from 'axios';
import { IncomingMessage } from 'http';
import { NextApiRequest } from 'next';
import { getWindow } from 'ssr-window';

import { getCookieItem } from '@zaritalk/utils/lib/cookie';
import { isClientSide } from '@zaritalk/utils/lib/device';

interface ICheckAuthToken {
  reqConfig?: { [key: string]: string };
  req?: (IncomingMessage & { cookies: Partial<{ [key: string]: string }> }) | NextApiRequest;
}

export const checkAuthToken = ({ reqConfig = {}, req }: ICheckAuthToken): AxiosRequestConfig | void => {
  const loginTokenName = process.env.LOGIN_TOKEN_NAME as string;

  if (isClientSide()) {
    const authToken = getCookieItem(loginTokenName, '');

    if (!authToken) {
      getWindow().location.replace(`/logout?next=${encodeURIComponent(getWindow().location.href)}`);
    } else {
      const addedConfig = reqConfig ? reqConfig : {};

      return { headers: { Authorization: `Bearer ${authToken}`, ...addedConfig } };
    }
  } else if (req) {
    const authToken = req.cookies[loginTokenName];
    return {
      headers: authToken
        ? {
            referer: req.headers.referer ?? '',
            userAgent: req.headers['user-agent'] ?? '',
            Authorization: `Bearer ${authToken}`,
            ...reqConfig,
          }
        : {
            referer: req.headers.referer ?? '',
            userAgent: req.headers['user-agent'] ?? '',
            ...reqConfig,
          },
    };
  } else {
    return {};
  }
};
