import axios from 'axios';
import { GetServerSidePropsContext } from 'next';
import { getDocument, getWindow } from 'ssr-window';
import { v4 as uuidv4 } from 'uuid';

import { TOKEN_MATCHED_APP_TYPE } from '@zaritalk/api';
import {
  FRONT_API_GATEWAY_ROUTES,
  SLACK_ERROR_REPORT_URL,
  SLACK_GLOBAL_ERROR_REPORT_URL,
  SLACK_SERVER_SIDE_REPORT_URL,
  ZARITALK_AWS_API_GATEWAY_URL,
} from '@zaritalk/constants';

import { getCookieItem, getBrowserVendor, getDeviceType, noop, parseJWT, isClientSide, debounce } from '../index';

type TErrorReportType = 'global' | 'local' | 'serverside';

const ERROR_REPORT_TYPE_MATCHED_URL = {
  global: SLACK_GLOBAL_ERROR_REPORT_URL,
  local: SLACK_ERROR_REPORT_URL,
  serverside: SLACK_SERVER_SIDE_REPORT_URL,
};

const ERROR_REPORT_TYPE_MATCHED_SLACK_CHANNEL_NAME = {
  global: '자리톡_프론트_전역에러_리포팅',
  local: '자리톡_프론트_에러_리포팅',
  serverside: '자리톡_프론트_서버_에러_리포팅',
};

let lastReportedError = null;
let errorCount = 0;

const postDebouncedReportError = debounce(
  (error: Error, reportType: TErrorReportType, ctx?: GetServerSidePropsContext) => {
    postSlackErrorReport({
      error,
      reportType,
      errorCount,
      ctx,
    });
    lastReportedError = null;
    errorCount = 0;
  },
  1000,
);

export const requestSlackErrorReport = (
  error: Error,
  reportType: TErrorReportType = 'local',
  ctx?: GetServerSidePropsContext,
) => {
  try {
    if (error?.message && lastReportedError?.message === error?.message) {
      errorCount++;

      if (errorCount === 10 || errorCount === 100) {
        postSlackErrorReport({
          error,
          reportType,
          errorCount: `${errorCount}+`,
          ctx,
        });
        return;
      }

      postDebouncedReportError(error, reportType, ctx);
    } else {
      lastReportedError = error;
      errorCount = 1;
      postSlackErrorReport({
        error,
        reportType,
        errorCount,
        ctx,
      });
    }
  } catch (e) {
    lastReportedError = null;
    errorCount = 0;
    return;
  }
};

interface ISlackErrorReportParams {
  error: Error;
  errorCount: number | string;
  reportType?: TErrorReportType;
  ctx?: GetServerSidePropsContext;
}

const postSlackErrorReport = ({ error, reportType = 'local', errorCount, ctx }: ISlackErrorReportParams) => {
  const ssrWindow = getWindow();

  const useAgent = isClientSide() ? ssrWindow.navigator.userAgent : ctx?.req.headers['user-agent'] ?? '';
  const loginToken = getCookieItem(process.env.LOGIN_TOKEN_NAME as string, false);

  const { PK, role } = parseJWT(loginToken);
  const formData = {
    token: loginToken !== '' ? loginToken : '로그인 안한 상태',
    role: role !== '' ? role : '로그인 안한 상태',
    pk: PK,
    triggerLocation: isClientSide() ? 'CLIENT' : 'SERVER',
    currentURI: isClientSide()
      ? ssrWindow.location.href
      : ctx?.req?.headers?.host ?? '' + ctx?.req?.url ?? ctx?.resolvedUrl ?? '',
    prevURI: isClientSide() ? getDocument().referrer : ctx?.req?.headers.referer ?? '',
    errorMessage: error?.message ?? '',
    error: error?.stack ?? '',
    browserInfo: useAgent !== '' ? getBrowserVendor(useAgent) : '전달된 브라우저 정보 없음',
    deviceInfo: useAgent !== '' ? getDeviceType(useAgent) : '전달된 기기정보 없음',
    userAgent: useAgent,
    appType: TOKEN_MATCHED_APP_TYPE[process.env.LOGIN_TOKEN_NAME as string] ?? 'ect',
  };

  const slackReportMessage = `
  ================================================
  - APP 타입 : ${formData.appType}
  - 사용자 토큰 : ${formData.token}
  - 사용자 타입 : ${formData.role}
  - 사용자 PK : ${formData.pk}
  - 브라우저 정보: ${formData.browserInfo}
  - 디바이스 정보: ${formData.deviceInfo}
  - USER_AGENT(유저 에이전트) : ${useAgent}
  - 에러 발생 위치 : ${formData.triggerLocation}
  - 현재 보는 페이지 URL : ${formData.currentURI}
  - 전에 보던 페이지 URL : ${formData.prevURI}
  - 받은 에러 메세지 : ${formData.errorMessage}
  - 받은 에러 정보 : ${formData.error}
  - 발생 횟수: ${errorCount}`;

  if (process.env.API_ENV !== 'localhost') {
    axios
      .post(
        ERROR_REPORT_TYPE_MATCHED_URL[reportType],
        { text: slackReportMessage },
        { headers: { 'Content-type': 'text/plain' } },
      )
      .catch(noop);

    if (process.env.API_ENV === 'production') {
      putErrorEventToApiGateway(error, reportType);
    }
  }
};

const putErrorEventToApiGateway = (error: Error, reportType: TErrorReportType) => {
  const uuid = uuidv4();

  axios
    .put(`${ZARITALK_AWS_API_GATEWAY_URL}${FRONT_API_GATEWAY_ROUTES.ERROR_REPORT}`, {
      uuid: uuid,
      message: error?.message,
      channelName: ERROR_REPORT_TYPE_MATCHED_SLACK_CHANNEL_NAME[reportType],
    })
    .catch(noop);
};

export const reportConsoleError = (error: unknown) => {
  requestSlackErrorReport(error as Error, 'local');
  console.error(error);
};
