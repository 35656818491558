import { isMobile } from 'mobile-device-detect';
import { useEffect, useRef, useState } from 'react';

import { Nullable } from '@zaritalk/types';

export default function useTouchAnimation<T extends HTMLElement>() {
  const touchRef = useRef<Nullable<T>>(null);
  const [isTouch, setIsTouch] = useState(false);

  useEffect(() => {
    if (!touchRef.current) return;

    const handleTouchStart = () => {
      setIsTouch(true);
    };

    const handleTouchEnd = () => {
      setIsTouch(false);
    };

    const touchElement = touchRef.current;

    if (isMobile) {
      touchElement.addEventListener('touchstart', handleTouchStart);
      touchElement.addEventListener('touchend', handleTouchEnd);
    } else {
      touchElement.addEventListener('mousedown', handleTouchStart);
      touchElement.addEventListener('mouseleave', handleTouchEnd);
    }

    return () => {
      if (isMobile) {
        touchElement.removeEventListener('touchstart', handleTouchStart);
        touchElement.removeEventListener('touchend', handleTouchEnd);
      } else {
        touchElement.removeEventListener('mousedown', handleTouchStart);
        touchElement.removeEventListener('mouseleave', handleTouchEnd);
      }
    };
  }, []);

  return {
    touchRef,
    isTouch,
  };
}
