import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { API_URL } from '@zaritalk/constants';
import { noop } from '@zaritalk/utils/lib/operation';

import { ApiReturn, slackReportFetcher, checkAuthToken } from '../../index';
import { axiosErrorHandler } from './errorHanleMiddleware';

export const putWithToken = async <T, R>(url: string, data: T): Promise<ApiReturn<R>> => {
  const config = checkAuthToken({}) as AxiosRequestConfig;

  try {
    const res = await axios.put<T, AxiosResponse<ApiReturn<R>>>(`${API_URL}${url}`, data, config);
    return res.data;
  } catch (err) {
    const catchError = err as Error | AxiosError<T>;
    return axiosErrorHandler<ApiReturn<R>>((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<ApiReturn<R>>;
        slackReportFetcher('PUT', url, data, response).catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};

export const putWithoutToken = async <T, R>(url: string, data: T): Promise<ApiReturn<R>> => {
  try {
    const res = await axios.put<T, AxiosResponse<ApiReturn<R>>>(`${API_URL}${url}`, data);
    return res.data;
  } catch (err) {
    const catchError = err as Error | AxiosError<T>;
    return axiosErrorHandler<ApiReturn<R>>((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<ApiReturn<R>>;
        slackReportFetcher('PUT', url, data, response).catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};

export const putExternalAPI = async <T, R>(url: string, data: T): Promise<ApiReturn<R>> => {
  try {
    const res = await axios.put<T, AxiosResponse<ApiReturn<R>>>(url, data);
    return res.data;
  } catch (err) {
    const catchError = err as Error | AxiosError<T>;
    return axiosErrorHandler<ApiReturn<R>>((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<ApiReturn<R>>;
        slackReportFetcher('PUT', url, data, response).catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};
