import { atom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import Cookies, { CookieAttributes } from 'js-cookie';
import { useCallback } from 'react';

const getCookies = (): { [key: string]: string } => {
  return Cookies.get() || {};
};

const setCookieItem = (key: string, value: any, options?: CookieAttributes) => {
  Cookies.set(key, value, options);
};

const removeCookieItem = (key: string, option = { path: '/', domain: '' }) => {
  Cookies.remove(key, option);
};

const cookieStateAtom = atomWithReset(getCookies());
const cookieValueAtom = atom<null, [newValue: { key: string; value: string }], void>(
  null,
  (get, set, newValue: { key: string; value: string }) => {
    set(cookieStateAtom, (prevState) => {
      return { ...prevState, [newValue.key]: newValue.value };
    });
  },
);

export function useCookieState(key: string, initialValue = '') {
  const cookies = useAtomValue(cookieStateAtom);
  const setCookieValue = useSetAtom(cookieValueAtom);

  const setValue = useCallback(
    (value: any, options?: CookieAttributes) => {
      setCookieValue({ key, value });
      const domain = process.env.API_ENV === 'localhost' ? '' : options?.domain ?? '';
      setCookieItem(key, value, { ...options, domain });
    },
    [key, setCookieValue],
  );

  const removeValue = useCallback(
    (path = '/', domain = '') => {
      setCookieValue({ key, value: '' });
      removeCookieItem(key, { path, domain });
    },
    [key, setCookieValue],
  );

  if (typeof window !== 'undefined') {
    return { cookieItem: cookies[key] ?? initialValue, setValue, removeValue };
  }

  return { cookieItem: '', setValue, removeValue };
}
