export const ZARITALK_FCM_TOKEN = 'ZARITALK_FCM_TOKEN';

export const ZARITALK_DEVICE_UUID = 'ZARITALK_DEVICE_UUID';
export const ZARITALK_UUID = 'ZARITALK_UUID';
export const ZARITALK_TOKEN = 'zaritalkToken';
export const REALTOR_TOKEN = 'gongsilToken';
export const LESSEE_TOKEN = 'LESSEE_TOKEN';
export const ZARITALK_ADMIN_TOKEN = 'ZARITALK_ADMIN_TOKEN';

export const KAKAO_TOKEN =
  process.env.API_ENV === 'localhost' || process.env.API_ENV === 'development'
    ? '4af0fbbec4c6accd296234d612c6fdef'
    : '83a9a6cba069496043bc5969c342722a';

export const JWT_TOKEN_EXPIRE_DAY = 180;
